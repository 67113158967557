import httpClient from '../httpClient/httpClient';

const LocationsService = {
  getLocationNames(clinicID = null) {
    return httpClient.get('api/get-location-names', {
      params: {
        clinicID,
      },
    });
  },
  getLocationData(locationID) {
    return httpClient.get('api/get-location-data', {
      params: {
        locationID,
      },
    });
  },
  updateLocationData(locationID, name, installationDate) {
    return httpClient.post('api/update-location-data', {
      locationID,
      name,
      installationDate,
    });
  },
  getLocationsOverview(page, size, filterData) {
    return httpClient.get("api/get-locations-overview", {
      params: {
        page,
        size,
        filterData
      }
    });
  },
  getLocationLocatedIns(clinicName = null, organizationName = null) {
    return httpClient.get("api/get-location-located-ins", {
      params: {
        clinicName,
        organizationName
      }
    });
  }
};

export default LocationsService;
